import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../sections/section';

export const query = graphql`
  query($slug: String!, $locale: String!) {
    contentfulLayoutMain(slug: { eq: $slug }, node_locale: { eq: $locale}) {
      id
      slug
      node_locale
      title
      description
      contentful_id
      menu {
        name
        type
        menuItems {
          id
          title
          url
        }
      }
      contentModule {
        ... on Node {
          id
          __typename
        }
      }
    }
  }
`;

const PageLayout = ({ data }) => {
  const {
    title,
    slug,
    description,
    menu,
    contentModule,
    node_locale,
  } = data.contentfulLayoutMain;

  return (
    <Layout menus={menu} nodeLocale={node_locale} slug={slug}>
      <SEO lang={node_locale} title={title} description={description} />
      {contentModule
        && contentModule.length > 0
        && contentModule.map((content) => (
          <Section
            contentModuleId={content.id}
            nodeLocale={node_locale}
            type={content.__typename}
            key={content.id}
          />
        ))}
    </Layout>
  );
};

export default PageLayout;
